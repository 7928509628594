import { ReactNode } from 'react';

import Image from 'src/components/Image';

import classes from './index.module.less';

interface Props {
	name?: string;
	position?: string;
	review?: string | ReactNode;
	image?: string;
}

const CardReview = ({ position = '', review = '', image = '', name = '' }: Props) => {
	return (
		<div className={classes.card}>
			<p className={classes.review}>{review}</p>

			<div className="d-flex align-items-center">
				<Image alt={name} src={image} height={71} width={71} />
				<div className="ml-4">
					<h5 className={classes.name}>{name}</h5>
					<p className={classes.position}>{position}</p>
				</div>
			</div>
		</div>
	);
};

export default CardReview;
