import { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import classNames from 'classnames';

import Button from 'src/components/Button';
import Divider from 'src/components/Divider';
import CardApproach from 'src/components/Home/CardApproach';
import CardProduct from 'src/components/Home/CardProduct';
import CardReview from 'src/components/Home/CardReview';
import CardWhatWeCanDo from 'src/components/Home/CardWhatWeCanDo';
import Image from 'src/components/Image';
import { ats, creoplay, econtainer, ek, medical, tagtag } from 'src/constants/projects';
import { IProject } from 'src/interface';

import classes from './index.module.less';
const Home = () => {
	const [selectedWhatWeCanDo, setSelectedWhatWeCanDo] = useState<number>(0);

	const listWhatWeCanDo = [
		{
			title: 'IoT Solutions',
			description:
				// eslint-disable-next-line max-len
				'Tailored Solutions for Seamless Integration and Optimal Performance with Innovative IoT Solutions to Reduce Costs.',
			image: '/images/what-we-can-do/iot-solution.png',
		},
		{
			title: 'Game Development',
			description:
				// eslint-disable-next-line max-len
				'Tailored Solutions for Seamless Integration and Optimal Performance with Innovative IoT Solutions to Reduce Costs.',
			image: '/images/what-we-can-do/game-development.png',
		},
		{
			title: 'Gamifications',
			description:
				// eslint-disable-next-line max-len
				'Tailored Solutions for Seamless Integration and Optimal Performance with Innovative IoT Solutions to Reduce Costs.',
			image: '/images/what-we-can-do/gamification.png',
		},
		{
			title: 'End to End Solutions',
			description:
				// eslint-disable-next-line max-len
				'Tailored Solutions for Seamless Integration and Optimal Performance with Innovative IoT Solutions to Reduce Costs.',
			image: '/images/what-we-can-do/end-to-end.png',
		},
	];
	const listOurApproach = [
		{
			title: 'Tailored Solutions for Your Needs',
			description:
				// eslint-disable-next-line max-len
				'Respond to the increasing demand for intelligent solutions.',
			image: '/images/home-section5-img1.png',
		},
		{
			title: 'Revolutionize Operations',
			description:
				// eslint-disable-next-line max-len
				'Harness the Power of Innovative IoT Solutions for Enhanced Efficiency and Cost Reduction.',
			image: '/images/home-section5-img2.png',
		},
		{
			title: 'Custom IoT Applications',
			description:
				// eslint-disable-next-line max-len
				'Bespoke applications crafted to meet your unique requirements.',
			image: '/images/home-section5-img3.png',
		},
		{
			title: 'Empowering Efficiency',
			description:
				// eslint-disable-next-line max-len
				'Tailored Solutions for Seamless Integration and Optimal Performance with Innovative IoT Solutions.',
			image: '/images/home-section5-img4.png',
		},
	];
	const listOurProduct: IProject[] = [econtainer, ats, medical, creoplay, tagtag, ek];
	const listReview = [
		{
			name: 'David Salim',
			position: 'COO of Nomina Games - Indonesia',
			review: (
				// eslint-disable-next-line max-len
				<span>
					“Although new, their innovative work and professionalism speak volumes about their
					commitment to setting a new benchmark in the industry. <br />
					Kudos to Tekuton for their stellar contribution!”
				</span>
			),
			image: '/images/home-section6-img1.png',
		},
		{
			name: 'Javier Tan',
			position: 'CEO of CreoEngine - Indonesia',
			review:
				// eslint-disable-next-line max-len
				'“Strong camaraderie and powerful technical team, TEKUTON is surely to bring reign over Indonesian Gaming space.”',
			image: '/images/home-section6-img2.png',
		},
	];
	const listPartner = [
		{ name: 'Nomina', image: '/images/partners/nomina.png' },
		{ name: 'FreshTurf', image: '/images/partners/freshturf.png' },
		{ name: 'Creo Engine', image: '/images/partners/creo.png' },
	];

	const selectedWWCDref = useRef(0);
	let loopWhatWeCanDo: any = null;
	const startLoopWhatWeCanDo = () => {
		loopWhatWeCanDo = setInterval(() => {
			setSelectedWhatWeCanDo(
				selectedWWCDref.current === listWhatWeCanDo.length - 1 ? 0 : selectedWWCDref.current + 1,
			);
		}, 5000);
	};
	const stopLoopWhatWeCanDo = () => {
		clearInterval(loopWhatWeCanDo);
	};
	useEffect(() => {
		startLoopWhatWeCanDo();
		return () => {
			stopLoopWhatWeCanDo();
		};
	}, []);
	useEffect(() => {
		selectedWWCDref.current = selectedWhatWeCanDo;
	}, [selectedWhatWeCanDo]);

	return (
		<div className={classes['home-container']}>
			<div className={classNames(classes.section, classes['section-1'])}>
				<div className="container animateSlideUp">
					<div className="row">
						<div className="col-lg-7">
							<h6 className={classes.label}>Where Precision Meets Play</h6>
							<h1 className={classes.title}>Your End-to-End Solution for a Connected Future</h1>
						</div>
						<div className="col-lg-5 d-flex">
							<p className="mt-auto mb-3">
								We are here to sculpt that future by empowering enterprises with the transformative
								power of IoT and Gaming.
							</p>
						</div>
					</div>
					<Divider className="mt-5" />
				</div>
			</div>
			<div className={classNames(classes.section, classes['section-2'])}>
				<div className="container animateSlideUp">
					<div className="row">
						<div className="col-lg-5">
							<div className={classes['image-container']}>
								<Image
									alt="We are Tekuton"
									src="/images/home-section2-img.png"
									layout="fill"
									objectFit="contain"
								/>
							</div>
						</div>
						<div className="col-lg-7 d-flex flex-column justify-content-center">
							<h6 className={classes.label}>We are Tekuton</h6>
							<h2 className={classes.title}>
								More than a technology firm, we are trailblazers in the digital realm
							</h2>
							<Link href="/contact">
								<Button className="mt-5" text="About Us" />
							</Link>
						</div>
					</div>
				</div>
			</div>
			<div className={classNames(classes.section, classes['section-3'])}>
				<div className="container animateSlideUp">
					<div className="row mb-5">
						<div className="col-lg-9 mx-auto text-center">
							<h6 className={classes.label}>What We Can Do</h6>
							<h2 className={classes.title}>
								Technical expertise to redefine what’s possible to deliver results.
							</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-6">
							{listWhatWeCanDo?.map(({ title, description }: any, index: number) => (
								<CardWhatWeCanDo
									key={index}
									title={title}
									description={description}
									selected={selectedWhatWeCanDo === index}
									onSelect={() => setSelectedWhatWeCanDo(index)}
								/>
							))}
						</div>
						<div className="col-lg-6">
							<div
								key={listWhatWeCanDo?.[selectedWhatWeCanDo]?.image}
								className={classNames(classes['image-container'], 'fadeIn')}
							>
								<Image
									alt={listWhatWeCanDo?.[selectedWhatWeCanDo]?.title ?? ''}
									src={listWhatWeCanDo?.[selectedWhatWeCanDo]?.image}
									layout="fill"
									objectFit="cover"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={classNames(classes.section, classes['section-4'])}>
				<div className="container animateSlideUp">
					<div className="row mb-5">
						<div className="col-lg-7">
							<h6 className={classes.label}>Our Product</h6>
							<h2 className={classes.title}>Explore Our Creative Showcase</h2>
						</div>
						<div className="col-lg-5 d-flex">
							<p className="mt-auto mb-3">
								{/* eslint-disable-next-line max-len */}
								{`Our portfolio is a testament to the extraordinary experiences we've crafted for our
								users. From sleek and user-friendly applications to immersive and thrilling games,
								we bring ideas to life in the digital realm.`}
							</p>
						</div>
					</div>
					<div className={classNames(classes['images-section'], 'mb-3')}>
						<div className="row">
							{listOurProduct?.map(({ title, description, images }: IProject, index: number) => {
								const isLandscape =
									index === 0 || (index >= 5 && (index % 5 === 0 || index % 5 === 1));
								return (
									<div key={title} className={isLandscape ? 'col-12 col-lg-6' : 'col-6 col-lg-3'}>
										<CardProduct
											type={isLandscape ? 1 : 2}
											title={title}
											description={description}
											image={isLandscape ? images[0] : images[1]}
										/>
									</div>
								);
							})}
						</div>
					</div>
					<div className="row mt-5">
						<Link className="mx-auto" href="/projects">
							<Button text="See All" />
						</Link>
					</div>
				</div>
			</div>
			<div className={classNames(classes.section, classes['section-5'])}>
				<div className="container animateSlideUp">
					<div className="row mb-5">
						<div className="col-lg-7">
							<h6 className={classes.label}>Our Approach</h6>
							<h2 className={classes.title}>Tailored Solution For Your Needs</h2>
						</div>
						<div className="col-lg-5 d-flex">
							<p className="mt-auto mb-3">
								At Tekuton, we pride ourselves on offering personalized services to meet your unique
								requirements. Explore our tailored solutions for a seamless IoT journey.
							</p>
						</div>
					</div>
					<div className="row">
						{listOurApproach?.map(({ title, description, image }: any) => (
							<div key={title} className="col-md-6 col-lg-3">
								<CardApproach title={title} description={description} image={image} />
							</div>
						))}
					</div>
				</div>
			</div>
			<div className={classNames(classes.section, classes['section-6'])}>
				<div className="container animateSlideUp">
					<div className="row mb-5">
						<div className="col-lg-7">
							<h6 className={classes.label}>Review</h6>
							<h2 className={classes.title}>What do people say about us?</h2>
						</div>
					</div>
					<div className="row">
						{listReview?.map(({ name, position, review, image }: any) => (
							<div key={name} className="col-md-6">
								<CardReview name={name} position={position} review={review} image={image} />
							</div>
						))}
					</div>
				</div>
			</div>
			<div className={classNames(classes.section, classes['section-7'])}>
				<div className="container animateSlideUp">
					<div className="row mb-4">
						<div className="col-lg-7">
							<h6 className={classes.label}>Proud to be partnering with:</h6>
						</div>
					</div>
					<div className="row">
						{listPartner?.map(({ name, image }: any) => (
							<div key={name} className="col-6 col-md-4 col-lg-3">
								<div className={classes['image-container']}>
									<Image alt={name} src={image} layout="fill" objectFit="contain" />
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
