import { GetStaticProps } from 'next';

import Home from 'src/containers/Home';

const HomePage = () => {
	return <Home />;
};
export const getStaticProps: GetStaticProps = () => {
	return {
		props: {
			header: {
				className: 'dark',
				scrolledClassName: 'light',
			},
		},
	};
};

export default HomePage;
