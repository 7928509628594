import Image from 'src/components/Image';

import classes from './index.module.less';

interface Props {
	image?: string;
	title?: string;
	description?: string;
}

const CardApproach = ({ image = '', title = '', description = '' }: Props) => {
	return (
		<div className={classes.card}>
			<div className={classes.image}>
				<Image alt={title} src={image} layout="fill" objectFit="contain" />
			</div>
			<h5 className="mt-4">{title}</h5>
			<p className="mt-4">{description}</p>
		</div>
	);
};

export default CardApproach;
