import classNames from 'classnames';

import classes from './index.module.less';

interface Props {
	className?: string;
	color?: string;
}
const Divider = ({ className = '', color = '#000' }: Props) => {
	return (
		<div
			className={classNames(classes.divider, 'animateWidthGrow', className)}
			style={{ borderColor: color }}
		></div>
	);
};

export default Divider;
