import classNames from 'classnames';

import classes from './index.module.less';

interface Props {
	title?: string;
	description?: string;
	selected?: boolean;
	onSelect?: () => void;
}

const CardWhatWeCanDo = ({ title, description, selected, onSelect }: Props) => {
	return (
		<div className={classNames(classes.card, { [classes.selected]: selected })} onClick={onSelect}>
			<h5>{title}</h5>
			<p>{description}</p>
		</div>
	);
};

export default CardWhatWeCanDo;
