import classNames from 'classnames';

import Image from 'src/components/Image';

import classes from './index.module.less';

interface Props {
	title?: string;
	description?: string;
	image?: string;
	type?: 1 | 2;
}

const CardProduct = ({ title = '', description = '', image = '', type = 1 }: Props) => {
	return (
		<div
			className={classNames(classes.card, {
				[classes['type-1']]: type === 1,
				[classes['type-2']]: type === 2,
			})}
		>
			<Image alt={title} src={image} layout="fill" objectFit="cover" defaultSrc="" />

			<div className={classes['card-mask']}>
				<div className={classes.content}>
					<span className={classes.title}>{title}</span>
					<span
						className={classes.description}
						dangerouslySetInnerHTML={{
							__html: description,
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default CardProduct;
