export const econtainer = {
	title: 'e-Container',
	description: 'IoT Solution',
	images: ['/images/projects/econtainer.png', '/images/projects/econtainer-square.png'],
};

export const ats = {
	title: 'ATS',
	description: '<span>IoT Solution - <br />End to End Solution</span>',
	images: ['/images/projects/ats.png', '/images/projects/ats-square.png'],
};

export const fastmix = {
	title: 'Fastmix',
	description: 'End to End Solution',
	images: ['/images/projects/fastmix.png', '/images/projects/fastmix-square.png'],
};

export const medical = {
	title: 'Medical',
	description: 'IoT Solution',
	images: ['/images/projects/medical.png', '/images/projects/medical-square.png'],
};

export const ek = {
	title: 'Evermore Knights',
	description: 'Game Development',
	images: ['/images/projects/ek.png', '/images/projects/ek-square.png'],
};

export const tagtag = {
	title: 'TagTag',
	description: 'Game Development - Gamification',
	images: ['/images/projects/tagtag.png', '/images/projects/tagtag-square.png'],
};

export const creoplay = {
	title: 'CreoPlay',
	description: 'End to End Solution',
	images: ['/images/projects/creoplay.png', '/images/projects/creoplay-square.png'],
};

export const hood = {
	title: 'Hood',
	description: 'End to End Solution',
	images: ['/images/projects/hood.png', '/images/projects/hood-square.png'],
};

export const emart = {
	title: 'eMart',
	description: 'IoT Solution',
	images: ['/images/projects/emart.png', '/images/projects/emart-square.png'],
};
